import React from 'react';

function SummaryTable(props) {
  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <table className="table table-striped summary-table">
      <thead>
        <tr>
          <th>Category</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {props.categories.map((category) => (
          <React.Fragment key={category.id}>
            <tr className="main-category" key={category.id}>
              <td>{category.name}</td>
              <td>{dollar.format(category.amount)}</td>
              <td><button className="btn btn-link btn-sm" onClick={() => props.showTransactions(category.id)}>View</button></td>
            </tr>
            {category.subCategories.map((subCategory) => (
              <tr key={subCategory.id}>
                <td className="sub-category">{subCategory.name}</td>
                <td>{dollar.format(subCategory.amount)}</td>
                <td><button className="btn btn-link btn-sm" onClick={() => props.showTransactions(subCategory.id)}>View</button></td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default SummaryTable;