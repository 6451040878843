import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Cookies from 'js-cookie';
import LoginPage from "./pages/LoginPage";
import TransactionsPage from "./pages/TransactionsPage";
import SummaryPage from "./pages/SummaryPage";
import TagSummaryPage from "./pages/TagSummaryPage";
import MonthlySummaryPage from "./pages/MonthlySummaryPage";
import AccountsPage from "./pages/AccountsPage";
import Header from "./components/Header";
import "./App.scss";

function App() {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const appClasses = [];
  const [navOpen, setNavOpen] = useState(false);

  function handleHamburgerClick() {
    setNavOpen(navOpen ? false : true);
  }

  function closeMenu() {
    setNavOpen(false);
  }

  if (pathname !== "/login") {
    appClasses.push("solid-bg");
  }

  return (
    <div className={"App " + appClasses.join(" ")}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <button className={"hamburger hamburger--collapse " + (navOpen ? 'is-active ' : '') + (pathname == '/login' ? 'd-none ' : '')} type="button" onClick={handleHamburgerClick}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="row">
          <Routes>
            <Route>
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Header navOpen={navOpen} closeMenu={closeMenu} />
                    <TransactionsPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/accounts"
                element={
                  <RequireAuth>
                    <Header navOpen={navOpen} closeMenu={closeMenu} />
                    <AccountsPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/summary"
                element={
                  <RequireAuth>
                    <Header navOpen={navOpen} closeMenu={closeMenu} />
                    <SummaryPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/tags"
                element={
                  <RequireAuth>
                    <Header navOpen={navOpen} closeMenu={closeMenu} />
                    <TagSummaryPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/monthly"
                element={
                  <RequireAuth>
                    <Header navOpen={navOpen} closeMenu={closeMenu} />
                    <MonthlySummaryPage />
                  </RequireAuth>
                }
              />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

function RequireAuth({ children }) {
  // let auth = useAuth();
  let location = useLocation();
  let token = Cookies.get('token');

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
