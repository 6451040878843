import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import TransactionCategory from '../components/TransactionCategory';

function TransactionModal(props) {
	const [transaction, setTransaction] = useState(null);
	const [splits, setSplits] = useState([]);
	const [error, setError] = useState("");
	const [splitCounter, setSplitCounter] = useState(1);
		const dollar = new Intl.NumberFormat('en-US', {
	    style: 'currency',
	    currency: 'USD',
  	});

	useEffect(() => {
    	getTransaction();
  	}, [props.transactionId]);

  	function getTransaction() {
		if (!props.transactionId) {
			return;
		}

		setError("");
		setSplits([]);
		setSplitCounter(1);

		fetch('/api/transactions/' + props.transactionId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Basic ' + Cookies.get('token')
			}
		})
			.then(response => response.json())
				.then(data => {
					const transaction = data.transaction;
					setTransaction(transaction);
					const newSplits = [];

					if (transaction.children) {
						transaction.children.forEach((childTransaction) => {
							newSplits.push({
								id: childTransaction.id,
								category: childTransaction.category,
								amount: childTransaction.amount
							});
						});
					} else {
						newSplits.push({
							category: transaction.category,
							amount: transaction.amount,
							new: true
						});
					}

					setSplits(newSplits);
			})
			.catch(err => console.log(err));
  	}

  	function formatDate(dateStr) {
		if (!dateStr) {
			return "";
		}

		const dateParts = dateStr.split(/\D/);
		const date = new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
		return date.toLocaleString('default', {
		  month: 'short',
		  day: 'numeric',
		  year: 'numeric',
		});
	}

  	function updateSplitCategory(transactionId, categoryId) {
  		const newSplits = splits.map((split) => {
  			if (split.id === transactionId) {
  				split.category = categoryId;
  			}
  			return split;
  		});
  		setSplits(newSplits);
  	}

  	function updateSplitAmount(e, splitId) {
  		const newSplits = splits.map((split) => {
  			if (split.id === splitId) {
  				split.amount = e.target.value;
  			}

  			return split;
  		});
  		setSplits(newSplits);
  	}

  	function splitTransaction() {
  		let amountLeft = transaction.amount;
  		splits.forEach((split) => amountLeft -= split.amount );

  		setSplits([
  			...splits,
  			{ id: splitCounter, category: null, amount: amountLeft.toFixed(2), new: true }
		]);
		setSplitCounter(splitCounter + 1);
  	}

  	function removeSplit(removeSplit) {
  		if (removeSplit.new) {
  			setSplits(splits.filter(split => split.id !== removeSplit.id));
  		} else {
  			const newSplits = splits.map((split) => {
	  			if (split.id === removeSplit.id) {
	  				split.delete = true;
	  			}

	  			return split;
	  		});
	  		setSplits(newSplits);
  		}
  	}

  	function saveSplits() {
  		let totalAmount = 0.0;
		splits.forEach((split) => totalAmount += split.delete ? 0 : parseFloat(split.amount));

  		if (totalAmount.toFixed(2) !== transaction.amount.toFixed(2)) {
			setError("Amounts must add up to " + dollar.format(transaction.amount));
  		} else {
  			// save
  			const splitTransactions = splits.map((split) => {
  				let splitTransaction = {
  					amount: split.amount,
  					category: split.category,
  				};

  				if (split.id && !split.new) {
  					splitTransaction.id = split.id;
  				}

  				if (split.delete) {
  					splitTransaction.delete = true;
  				}

  				return splitTransaction;
  			});

  			fetch('/api/transactions/' + props.transactionId + '/split', {
		      	method: 'POST',
		      	headers: {
		        	'Content-Type': 'application/json',
		        	'Authorization': 'Basic ' + Cookies.get('token')
		      	},
		      	body: JSON.stringify({splitTransactions: splitTransactions})
		    })
		      	.then(response => response.json())
		      	.then(data => {
		      		// close modal and refresh transactions
		      		props.getTransactions();
		        	props.close();
		      	})
		      	.catch(err => console.log(err));
  		}
  	}

	if (!props.open || !props.transactionId) {
		return (
			<div>
			</div>
		);
	}

	return (
		<div>
			<div className="modal modal-open" tabIndex="-1" onClick={props.close}>
				<div className="modal-dialog">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div className="modal-header">
							<div className="modal-title"><h4>{transaction?.name}</h4></div>
							<button type="button" className="btn-close" aria-label="Close" onClick={props.close}></button>
						</div>
						<div className="modal-body">
							<h5>{formatDate(transaction?.date)}</h5>
							<table className="table">
								<thead>
									<tr>
										<th>Category</th>
										<th>Amount</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{splits.map((split, i) => (
										!split.delete &&
											<tr key={split.id}>
												<td><TransactionCategory transaction={split} updateCategory={updateSplitCategory} /></td>
												<td><input type="number" value={split.amount} className="form-control" onChange={(e) => updateSplitAmount(e, split.id)} /></td>
												<td>{ i > 0 && <button className="btn btn-danger remove-split" onClick={() => removeSplit(split)}></button> } </td>
											</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<th>Total</th>
										<th>
											{dollar.format(transaction?.amount)}
										</th>
										<th></th>
									</tr>
									<tr>
										<th colSpan="3">
											{ error && <p className="error text-danger">{error}</p> }
											<button className="btn btn-light float-end" onClick={splitTransaction}>Split</button>
										</th>
									</tr>
								</tfoot>
							</table>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" onClick={saveSplits}>Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TransactionModal;