import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Categories from '../Categories';

function CategoryTransactions(props) {
	const [transactions, setTransactions] = useState([]);
	const [page, setPage] = useState(1);
	const dollar = new Intl.NumberFormat('en-US', {
	    style: 'currency',
	    currency: 'USD',
  	});

	useEffect(() => {
		if (props.open && props.categories) {
    		getTransactions();
    	}
	}, [props.categories, props.open]);

	function getLastDayOfMonth(date) {
		if (typeof date !== "object") {
			date = new Date(date);
		}

		const lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
		lastDay.setHours(0, 0, 0);

		return lastDay;
	}

  	function getTransactions() {
  		// build params
  		const params = new URLSearchParams({
  			page: (page || 1),
  			categories: props.categories.join(','),
  		});

		if (props.selDate) {
			const startDate = new Date(props.selDate);
			const endDate = getLastDayOfMonth(startDate);
			params.append('startDate', startDate.toISOString().split('T')[0]);
			params.append('endDate', endDate.toISOString().split('T')[0]);
		}

		if (props.selTag) {
			params.append('tags[]', props.selTag);
		}

  		fetch('/api/transactions?' + params.toString(), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Basic ' + Cookies.get('token')
			}
		})
			.then(response => response.json())
			.then(data => {
				setTransactions(data.transactions);
				setPage(parseInt(data.page));
				// if (data.numPages > 1 && data.page < data.numPages) {
					// getTransactions();
				// }
			})
			.catch(err => console.log(err));
  	}

  	function getCategoryName(categoryId) {
  		for (let i = 0; i < Categories.length; i++) {
  			if (categoryId === Categories[i].id) {
  				return Categories[i].name;
  			}

			if (Categories[i].subCategories) {
				for (let x = 0; x < Categories[i].subCategories.length; x++) {
					if (categoryId === Categories[i].subCategories[x].id) {
						return Categories[i].subCategories[x].name;
					}
				}
			}
  		}
  	}

  	function dateFormatPretty(startDate, endDate) {
		if (typeof startDate !== "object") {
			startDate = new Date(startDate + "T00:00:00");
		}

  		return startDate.toLocaleString('default', {
  			month: 'long',
  			year: 'numeric'
  		});
  	}

  	function shortDate(dateStr) {
  		const dateParts = dateStr.split(/\D/);
	    const date = new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
	    return date.toLocaleString('default', {
	      month: 'short',
	      day: 'numeric',
	    });
  	}

  	function getTotal(transactions) {
  		return dollar.format(transactions.reduce((total, transaction) => total + parseFloat(transaction.amount), 0));
  	}

	if (!props.open) {
		return (
			<div>
			</div>
		);
	}

	return (
		<div>
			<div className="modal modal-open" tabIndex="-1" onClick={props.close}>
				<div className="modal-dialog modal-lg">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div className="modal-header">
							<div className="modal-title"><h4>{getCategoryName(props.categories[0])} Transactions</h4></div>
							<button type="button" className="btn-close" aria-label="Close" onClick={props.close}></button>
						</div>
						<div className="modal-body">
							{ props.selDate &&
								<h5>{dateFormatPretty(props.selDate, getLastDayOfMonth(props.selDate))}</h5>
							}
							{ props.selTag &&
								<h5>{props.selTagName}</h5>
							}
							<table className="table">
								<thead>
									<tr>
										<th>Date</th>
										<th>Name</th>
										<th></th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{transactions.map((transaction) => (
										<tr key={transaction.id}>
											<td>{shortDate(transaction.date)}</td>
											<td>{transaction.name}</td>
											<td>{getCategoryName(transaction.category)}</td>
											<td>{dollar.format(transaction.amount)}</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<th></th>
										<th></th>
										<th></th>
										<th>{getTotal(transactions)}</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CategoryTransactions;