import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { getSummarySeries } from '../lib/Summary';

function SummaryChart(props) {
  const [selCategory, setSelCategory] = useState('');

  const chartOptions = {
    title: "Categories"
  };

  const chartFormatters = [
    {
      type: "NumberFormat",
      column: 1,
      options: {
        prefix: "$",
        suffix: "%"
      }
    }
  ];

  return (
    <React.Fragment>
      <div className="row g-3 align-items-center justify-content-center">
        <div className="col-auto">
          <label htmlFor="sel-category">Show</label>
        </div>
        <div className="col-auto">
          <select className="form-control" id="sel-category" value={selCategory} onChange={(e) => setSelCategory(e.target.value)}>
            <option value="">All Categories</option>
            {props.categories.map((category) => (
              category.id !== 67 &&
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>
      </div>
      <Chart 
        chartType="PieChart"
        data={getSummarySeries(selCategory, props.categories)}
        options={chartOptions}
        width={"100%"}
        height={"400px"}
        formatters={chartFormatters}
      />
    </React.Fragment>
  )
}

export default SummaryChart;