import React, { useState, useEffect } from 'react';
import Categories from '../Categories';

function TransactionCategory(props) {

	function getCategoryName() {
		const categoryId = props.transaction.category || 123;

		Categories.forEach((category) => {
			if (category.id === categoryId) {
				return category.name;
			}

			category.subCategories.forEach((subCategory) => {
				if (subCategory.id === categoryId) {
					return subCategory.name;
				}
			})
		});
	}

	function updateCategory(e) {
		props.updateCategory(props.transaction.id, e.target.value);
	}

	return (
		<div>
			<select className="form-control" value={props.transaction.category || 123} onChange={e => updateCategory(e)} disabled={props.disabled}>
				{Categories.map(category => (
					<React.Fragment key={category.id}>
						<option key={category.id} value={category.id}>{category.name}</option>
						{category.subCategories.map(subCategory => (
							<option key={subCategory.id} value={subCategory.id}>&nbsp;&nbsp;&nbsp;{subCategory.name}</option>
						))}
					</React.Fragment>
				))}
			</select>
		</div>
	);
}

export default TransactionCategory;