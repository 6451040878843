import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import CategoryTransactions from '../components/CategoryTransactions';
import SummaryChart from '../components/SummaryChart';
import SummaryTable from '../components/SummaryTable';
import { transformCategories, getTotalExpenses, getTotalIncome } from '../lib/Summary';

function SummaryPage() {
  const lastMonth = new Date(new Date().setDate(0));
  lastMonth.setDate(1);
  lastMonth.setHours(0, 0, 0);
  const endLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
  endLastMonth.setHours(0, 0, 0);
  const thisMonth = new Date(new Date().setMonth(lastMonth.getMonth() + 2));
  thisMonth.setDate(0);
  const endOfThisMonth = thisMonth.toISOString().split('T')[0];
  const [categories, setCategories] = useState([]);
  const [startDate] = useState(lastMonth);
  const [transactionCategories, setTransactionCategories] = useState([]);
  const [openCategoryTransactions, setOpenCategoryTransactions] = useState(false);
  const [selDate, setSelDate] = useState(startDate.toISOString().split('T')[0]);
  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    getSummary();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSummary(date) {
    const dateStr = date || startDate.toISOString().split('T')[0];

    fetch('/api/transactions/summary?startDate=' + dateStr, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setCategories(transformCategories(data.summary));
      })
      .catch(err => console.log(err));
  }

  function showTransactions(categoryId) {
    // get all sub categories of category if it has
    const ids = [categoryId];
    categories.forEach((category) => {      
      if (category.id === categoryId && category.subCategories) {
        category.subCategories.forEach((subCategory) => {
          ids.push(subCategory.id);
        });
      }
      return ids;
    });

    setTransactionCategories(ids);
    setOpenCategoryTransactions(true);
  }

  function closeCategoryTransactions() {
    setOpenCategoryTransactions(false);
  }

  function getMonthName(date) {
    const dateParts = date.split('-');
    const dateObj = new Date(dateParts[0], parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
    return dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  function handleSelDate(e) {
    const dateParts = e.target.value.split("-");
    const newDate = dateParts[0] + "-" + dateParts[1] + "-01";
    setSelDate(newDate);
    getSummary(newDate);
  }

  const totalExpenses = getTotalExpenses(categories);
  const totalIncome = getTotalIncome(categories) * -1;
  const net = (getTotalIncome(categories) * -1) - getTotalExpenses(categories);
  const netClass = net < 0 ? "text-danger" : "text-success";

  return (
    <div className="col-lg-10 page-container">
      <h2>Summary for {getMonthName(selDate)}</h2>
      <div className="row g-3 align-items-center mt-3">
        <div className="col-auto">
          <label htmlFor="month" className="col-form-label">Select a Month</label>
        </div>
        <div className="col-auto">
          <input type="date" id="month" value={selDate} className="form-control" onChange={handleSelDate} max={endOfThisMonth} />
        </div>
      </div>
      <div className="my-3">
        <p>Total Expenses: <strong>{dollar.format(totalExpenses)}</strong></p>
        <p>Total Income: <strong>{dollar.format(totalIncome)}</strong></p>
        <p>Net: <strong className={netClass}>{dollar.format(net)}</strong></p>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <SummaryTable categories={categories} showTransactions={showTransactions} />
        </div>
        <div className="col-lg-6">
          <SummaryChart categories={categories}/>
        </div>
      </div>
      <CategoryTransactions open={openCategoryTransactions} close={closeCategoryTransactions} categories={transactionCategories} selDate={selDate}/>
    </div>
  );
}

export default SummaryPage;