const Categories = [
	{
		id: 126,
		name: "Auto & Transport",
		subCategories: [
			{
				id: 2,
				name: "Auto Insurance",
			},
			{
				id: 3,
				name: "Auto Payment",
			},
			{
				id: 4,
				name: "Parking",
			},
			{
				id: 5,
				name: "Public Transportation",
			},
			{
				id: 6,
				name: "Ride Share",
			},
			{
				id: 7,
				name: "Service & Parts",
			},
		],
	},
	{
		id: 8,
		name: "Bills & Utilities",
		subCategories: [
			{
				id: 9,
				name: "Home Phone",
			},
			{
				id: 10,
				name: "Internet",
			},
			{
				id: 11,
				name: "Mobile Phone",
			},
			{
				id: 12,
				name: "Television",
			},
			{
				id: 13,
				name: "Utilities",
			},
		],
	},
	{
		id: 14,
		name: "Business Services",
		subCategories: [
			{
				id: 15,
				name: "Advertising",
			},
			{
				id: 16,
				name: "Legal",
			},
			{
				id: 17,
				name: "Office Supplies",
			},
			{
				id: 18,
				name: "Printing",
			},
			{
				id: 19,
				name: "Shipping",
			},
		],
	},
	{
		id: 20,
		name: "Education",
		subCategories: [
			{
				id: 21,
				name: "Books & Supplies",
			},
			{
				id: 22,
				name: "Student Loan",
			},
			{
				id: 23,
				name: "Tuition",
			},
		],
	},
	{
		id: 24,
		name: "Entertainment",
		subCategories: [
			{
				id: 25,
				name: "Amusement",
			},
			{
				id: 26,
				name: "Arts",
			},
			{
				id: 27,
				name: "Movies & DVDs",
			},
			{
				id: 28,
				name: "Music",
			},
			{
				id: 29,
				name: "Newspapers & Magazines",
			},
		],
	},
	{
		id: 30,
		name: "Fees & Charges",
		subCategories: [
			{
				id: 31,
				name: "ATM Fee",
			},
			{
				id: 32,
				name: "Bank Fee",
			},
			{
				id: 33,
				name: "Finance Charge",
			},
			{
				id: 34,
				name: "Late Fee",
			},
			{
				id: 35,
				name: "Service Fee",
			},
			{
				id: 36,
				name: "Trade Commissions",
			},
		],

	},
	{
		id: 37,
		name: "Financial",
		subCategories: [
			{
				id: 38,
				name: "Financial Advisor",
			},
			{
				id: 39,
				name: "Life Insurance",
			},
		],
	},
	{
		id: 40,
		name: "Food & Dining",
		subCategories: [
			{
				id: 41,
				name: "Alcohol & Bars",
			},
			{
				id: 42,
				name: "Coffee Shops",
			},
			{
				id: 43,
				name: "Fast Food",
			},
			{
				id: 44,
				name: "Food Delivery",
			},
			{
				id: 45,
				name: "Groceries",
			},
			{
				id: 46,
				name: "Restaurants",
			},
		],
	},
	{
		id: 47,
		name: "Gifts & Donations",
		subCategories: [
			{
				id: 48,
				name: "Charity",
			},
			{
				id: 49,
				name: "Gift",
			},
		],
	},
	{
		id: 50,
		name: "Health & Fitness",
		subCategories: [
			{
				id: 51,
				name: "Dentist",
			},
			{
				id: 52,
				name: "Doctor",
			},
			{
				id: 53,
				name: "Eyecare",
			},
			{
				id: 54,
				name: "Gym",
			},
			{
				id: 55,
				name: "Health Insurance",
			},
			{
				id: 56,
				name: "Pharmacy",
			},
			{
				id: 57,
				name: "Sports",
			},
		],
	},
	{
		id: 58,
		name: "Hide From Budgets & Trends",
		subCategories: [],
	},
	{
		id: 59,
		name: "Home",
		subCategories: [
			{
				id: 60,
				name: "Furnishings",
			},
			{
				id: 61,
				name: "Home Improvement",
			},
			{
				id: 62,
				name: "Home Insurance",
			},
			{
				id: 63,
				name: "Home Services",
			},
			{
				id: 64,
				name: "Home Supplies",
			},
			{
				id: 65,
				name: "Lawn & Garden",
			},
			{
				id: 66,
				name: "Mortgage & Rent",
			},
		],
	},
	{
		id: 67,
		name: "Income",
		subCategories: [
			{
				id: 68,
				name: "Bonus",
			},
			{
				id: 69,
				name: "Interest Income",
			},
			{
				id: 70,
				name: "Paycheck",
			},
			{
				id: 71,
				name: "Reimbursement",
			},
			{
				id: 72,
				name: "Rental Income",
			},
			{
				id: 73,
				name: "Returned Purchase",
			},
		],
	},
	{
		id: 74,
		name: "Investments",
		subCategories: [
			{
				id: 75,
				name: "Buy",
			},
			{
				id: 76,
				name: "Deposit",
			},
			{
				id: 77,
				name: "Dividend & Cap Gains",
			},
			{
				id: 78,
				name: "Sell",
			},
			{
				id: 79,
				name: "Withdrawal",
			},
		],
	},
	{
		id: 80,
		name: "Kids",
		subCategories: [
			{
				id: 81,
				name: "Allowance",
			},
			{
				id: 82,
				name: "Baby Supplies",
			},
			{
				id: 83,
				name: "Babysitter & Daycare",
			},
			{
				id: 84,
				name: "Child Support",
			},
			{
				id: 85,
				name: "Kids Activities",
			},
			{
				id: 86,
				name: "Toys",
			},
		],
	},
	{
		id: 87,
		name: "Loans",
		subCategories: [
			{
				id: 88,
				name: "Loan Fees and Charges",
			},
			{
				id: 89,
				name: "Loan Insurance",
			},
			{
				id: 90,
				name: "Loan Interest",
			},
			{
				id: 91,
				name: "Loan Payment",
			},
			{
				id: 92,
				name: "Loan Principal",
			},
		],
	},
	{
		id: 93,
		name: "Misc Expenses",
		subCategories: [],
	},
	{
		id: 94,
		name: "Personal Care",
		subCategories: [
			{
				id: 95,
				name: "Cosmetics/Skin Care",
			},
			{
				id: 96,
				name: "Hair",
			},
			{
				id: 97,
				name: "Laundry",
			},
			{
				id: 98,
				name: "Spa & Massage",
			},
		],
	},
	{
		id: 99,
		name: "Pets",
		subCategories: [
			{
				id: 100,
				name: "Pet Food & Supplies",
			},
			{
				id: 101,
				name: "Pet Grooming",
			},
			{
				id: 102,
				name: "Veterinary",
			},
		],
	},
	{
		id: 103,
		name: "Shopping",
		subCategories: [
			{
				id: 104,
				name: "Books",
			},
			{
				id: 105,
				name: "Clothing",
			},
			{
				id: 106,
				name: "Electronics & Software",
			},
			{
				id: 107,
				name: "Hobbies",
			},
			{
				id: 108,
				name: "Sporting Goods",
			},
		],
	},
	{
		id: 109,
		name: "Taxes",
		subCategories: [
			{
				id: 110,
				name: "Federal Tax",
			},
			{
				id: 111,
				name: "Local Tax",
			},
			{
				id: 112,
				name: "Property Tax",
			},
			{
				id: 113,
				name: "Sales Tax",
			},
			{
				id: 114,
				name: "State Tax",
			},
		],
	},
	{
		id: 115,
		name: "Transfer",
		subCategories: [
			{
				id: 116,
				name: "Credit Card Payment",
			},
			{
				id: 117,
				name: "Transfer for Cash Spending",
			},
		],
	},
	{
		id: 118,
		name: "Travel",
		subCategories: [
			{
				id: 119,
				name: "Air Travel",
			},
			{
				id: 120,
				name: "Hotel",
			},
			{
				id: 121,
				name: "Rental Car & Taxi",
			},
			{
				id: 122,
				name: "Vacation",
			},
		],
	},
	{
		id: 123,
		name: "Uncategorized",
		subCategories: [
			{
				id: 124,
				name: "Cash & ATM",
			},
			{
				id: 125,
				name: "Check",
			},
		],
	},
	/** DO NOT USE 126 **/
];

export default Categories;