import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import TransactionCategory from '../components/TransactionCategory';

function AddTransactionModal(props) {
	const [name, setName] = useState("");
	const [amount, setAmount] = useState(0);
	const [date, setDate] = useState("");
	const [category, setCategory] = useState(0);
	const [keepDetails, setKeepDetails] = useState(false);
	const [error, setError] = useState("");

	function saveTransaction(e) {
		e.preventDefault();

		// validate
		if (
			!name ||
			!amount ||
			!date ||
			!category
		) {
			setError("Please fill out all fields");
		} else {
			// save transaction
			fetch('/api/transactions', {
		      	method: 'POST',
		      	headers: {
		        	'Content-Type': 'application/json',
		        	'Authorization': 'Basic ' + Cookies.get('token')
		      	},
		      	body: JSON.stringify({
		      		name: name,
		      		amount: amount,
		      		date: date,
		      		category: category
		      	})
		    })
		      	.then(response => response.json())
		      	.then(data => {
		      		// close modal and refresh transactions
					if (!keepDetails) {
						setName("");
						setAmount(0);
						setDate("");
						setCategory(0);
					}
		      		props.getTransactions();
		        	props.close();
		      	})
		      	.catch(err => console.log(err));
		}
	}

	if (!props.open) {
		return (
			<div>
			</div>
		);
	}

	return (
		<div>
			<div className="modal modal-open" tabIndex="-1" onClick={props.close}>
				<div className="modal-dialog">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div className="modal-header">
							<div className="modal-title"><h4>Add a Transaction</h4></div>
							<button type="button" className="btn-close" aria-label="Close" onClick={props.close}></button>
						</div>
						<form>
							<div className="modal-body">							
								<div className="mb-3">
									<label htmlFor="transactionName" className="form-label">Name</label>
									<input type="text" className="form-control" id="transactionName" value={name} onChange={(e) => setName(e.target.value)} />
								</div>
								<div className="mb-3">
									<label htmlFor="transactionDate" className="form-label">Date</label>
									<input type="date" className="form-control" id="transactionDate" value={date} onChange={(e) => setDate(e.target.value)} />
								</div>
								<div className="mb-3">
									<label htmlFor="transactionAmount" className="form-label">Amount</label>
									<input type="number" className="form-control" id="transactionAmount" value={amount} onChange={(e) => setAmount(e.target.value)} />
								</div>
								<div className="mb-3">
									<label htmlFor="transactionCategory" className="form-label">Category</label>
									<TransactionCategory transaction={{category: category}} updateCategory={(id, category) => {setCategory(category)}} />
								</div>
								<div className="mb-3">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" checked={keepDetails} id="keepDetails" onChange={(e) => setKeepDetails(!keepDetails)}/>
										<label className="form-check-label" htmlFor="keepDetails">
											Use same details for next transaction
										</label>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-primary" onClick={saveTransaction}>Add</button>
								{ error && <p className="error text-danger">{error}</p> }
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddTransactionModal;