import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import CategoryTransactions from '../components/CategoryTransactions';
import SummaryChart from '../components/SummaryChart';
import SummaryTable from '../components/SummaryTable';
import FilterTag from '../components/FilterTag';
import { transformCategories, getTotalExpenses } from '../lib/Summary';

function TagSummaryPage() {
  const [categories, setCategories] = useState([]);;
  const [transactionCategories, setTransactionCategories] = useState([]);
  const [openCategoryTransactions, setOpenCategoryTransactions] = useState(false);
  const [selTag, setSelTag] = useState("");
  const [selTagName, setSelTagName] = useState("");
  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    getSummary();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSummary(tag) {
    if (!tag) {
      setCategories([]);
      setTransactionCategories([]);
      setOpenCategoryTransactions(false);
      return;
    }

    fetch('/api/transactions/summary?tag=' + tag, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setCategories(transformCategories(data.summary));
      })
      .catch(err => console.log(err));
  }

  function showTransactions(categoryId) {
    // get all sub categories of category if it has
    const ids = [categoryId];
    categories.forEach((category) => {      
      if (category.id === categoryId && category.subCategories) {
        category.subCategories.forEach((subCategory) => {
          ids.push(subCategory.id);
        });
      }
      return ids;
    });

    setTransactionCategories(ids);
    setOpenCategoryTransactions(true);
  }

  function closeCategoryTransactions() {
    setOpenCategoryTransactions(false);
  }

  function setTag(tagId) {
    getSummary(tagId);
    setSelTag(tagId);
  }

  const totalExpenses = getTotalExpenses(categories);

  return (
    <div className="col-lg-10 page-container">
      <h2>Tag Summary</h2>
      <div className="col-lg-3">
        <FilterTag tag={selTag} setTag={setTag} setTagName={setSelTagName} />
      </div>
      {selTag &&
        <React.Fragment>
          <div className="my-3">
            <p>Total Expenses: <strong>{dollar.format(totalExpenses)}</strong></p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <SummaryTable categories={categories} showTransactions={showTransactions} />
            </div>
            <div className="col-lg-6">
              <SummaryChart categories={categories}/>
            </div>
          </div>
          <CategoryTransactions open={openCategoryTransactions} close={closeCategoryTransactions} categories={transactionCategories} selTag={selTag} selTagName={selTagName}/>
        </React.Fragment>
      }
    </div>
  );
}

export default TagSummaryPage;