import React, { useState, useEffect } from 'react';
import {
  NavLink,
  useLocation
} from "react-router-dom";

function Header(props) {
	const location = useLocation();

	useEffect(() => {
    props.closeMenu();
  }, [location]);

	return (
		<div className={"col-lg-2 nav-col " + (props.navOpen ? 'open' : '')}>
			<nav className="vh-100">
				<h1>Expenses Tracker</h1>
				<ul>
					<li><NavLink to="/" className={({ isActive, isPending }) => isActive ? "active" : ""}>Transactions</NavLink></li>
					<li><NavLink to="/summary" className={({ isActive, isPending }) => isActive ? "active" : ""}>Summary</NavLink></li>
					<li><NavLink to="/tags" className={({ isActive, isPending }) => isActive ? "active" : ""}>Tags</NavLink></li>
					<li><NavLink to="/monthly" className={({ isActive, isPending }) => isActive ? "active" : ""}>Monthly</NavLink></li>
					<li><NavLink to="/accounts" className={({ isActive, isPending }) => isActive ? "active" : ""}>Accounts</NavLink></li>
				</ul>
			</nav>
		</div>
	);
}

export default Header;