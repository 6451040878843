import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function FilterTag(props) {
	const [tags, setTags] = useState([]);

	useEffect(() => {
		// get tags
		fetch('/api/transactions/tags', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setTags(data.tags);
      })
      .catch(err => console.log(err));
	}, []);

	function handleChange(e) {
    props.setTag(e.target.value);

    if (props.setTagName) {
      props.setTagName(e.target.options[e.target.selectedIndex].innerHTML);
    }
	}

	return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <label htmlFor="tag" className="col-form-label">Tag</label>
      </div>
      <div className="col-auto">
    		<select className="form-control form-control-sm" onChange={handleChange} value={props.tag} id="tag">
    			<option value={""} key={0}>All Tags</option>
          {tags && tags.length && tags.map(tag => (
            <option value={tag.id} key={tag.id}>{tag.name}</option>
          ))}
    		</select>
      </div>
    </div>
	);
}

export default FilterTag;