import Categories from '../Categories';

function transformCategories(rawCategories) {
  let prettyCategories = JSON.parse(JSON.stringify(Categories.slice()));
  rawCategories.forEach((rawCategory) => {
    for (let x = 0; x < prettyCategories.length; x++) {
      if (rawCategory.category === prettyCategories[x].id) {
        prettyCategories[x].amount = parseFloat(rawCategory.total_amount);
        break;
      }

      for (let y = 0; y < prettyCategories[x].subCategories.length; y++) {
        if (rawCategory.category === prettyCategories[x].subCategories[y].id) {
          prettyCategories[x].subCategories[y].amount = parseFloat(rawCategory.total_amount);
          break;
        }
      }
    }
  });

  for (let x = 0; x < prettyCategories.length; x++) {
    if (!prettyCategories[x].amount) prettyCategories[x].amount = 0;
    for(let y = 0; y < prettyCategories[x].subCategories.length; y++) {
      if (prettyCategories[x].subCategories[y].amount) {
        prettyCategories[x].amount += prettyCategories[x].subCategories[y].amount;
      }
    }
  }

  prettyCategories = prettyCategories.filter((prettyCategory) => prettyCategory.amount);

  for (let x = 0; x < prettyCategories.length; x++) {
    prettyCategories[x].subCategories = prettyCategories[x].subCategories.filter((subCategory) => subCategory.amount);
  }

  return prettyCategories;
}

function getSummarySeries(selCategory, categories) {
  const series = [];
  categories.forEach((category) => {
    // skip income
    if (category.id === 67) {
      return;
    }

    // skip negative categories
    if (category.amount < 0) {
      return;
    }

    if (!selCategory) {
      series.push([category.name, category.amount]);
    }

    if (parseInt(selCategory) === category.id) {
      category.subCategories.forEach((subCategory) => {
        series.push([subCategory.name, subCategory.amount]);
      })
    }
  });

  series.unshift(["Category", "Amount"]);

  return series;
}

function getTotalExpenses(categories) {
  return categories.reduce((amount, category) => {
    if (category.amount > 0) {
      return amount += category.amount;
    } else {
      return amount;
    }
  }, 0);
}

function getTotalIncome(categories) {
  return categories.reduce((amount, category) => {
    if (category.amount < 0) {
      return amount += category.amount;
    } else {
      return amount;
    }
  }, 0);
}

export {
  transformCategories,
  getSummarySeries,
  getTotalExpenses,
  getTotalIncome,
}