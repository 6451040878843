import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Chart } from "react-google-charts";

function MonthlySummaryPage() {
  const [summaryData, setSummaryData] = useState([]);
  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    getMonthlySummary();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getMonthlySummary(date) {
    fetch('/api/transactions/monthly', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        const transformedData = [
          ['Month', 'Income', 'Expenses'],
        ];

        data.transactions.forEach((month) => {
          console.log(`${month.month}-01T00:00:00`);
          const date = new Date(Date.parse(`${month.month}-01T00:00:00`));
          transformedData.push([
            date.toLocaleDateString('en-US', {year: 'numeric', month: 'short'}),
            month.income * -1,
            month.expenses,
          ]);
        });

        setSummaryData(transformedData);
      })
      .catch(err => console.log(err));
  }

  const options = {
    title: "Income vs. Expenses",
    curveType: "function",
    legend: { position: "bottom" },
    colors: ['green', 'red'],
  };

  const chartFormatters = [
    {
      type: "NumberFormat",
      column: 1,
      options: {
        prefix: "$",
      }
    },
    {
      type: "NumberFormat",
      column: 2,
      options: {
        prefix: "$",
      }
    }
  ];

  return (
    <div className="col-lg-10 page-container">
      <h2>Monthly Net Income Summary</h2>
      <div className="row">
        <div className="col-lg-12">
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={summaryData}
            options={options}
            formatters={chartFormatters}
          />
        </div>
        <div className="col-lg-12">
          <table className="table table-striped summary-table">
            <thead>
              <tr>
                <th>Month</th>
                <th>Income</th>
                <th>Expenses</th>
                <th>Net</th>
              </tr>
            </thead>
            <tbody>
              {summaryData.slice(1).reverse().map((month) => (
                <tr key={month[0]}>
                  <td>{month[0]}</td>
                  <td>{dollar.format(month[1])}</td>
                  <td>{dollar.format(month[2])}</td>
                  <td className={month[1] - month[2] > 0 ? "text-success" : "text-danger"}>{dollar.format(month[1] - month[2])}</td>
                </tr>
              ))}
            </tbody>
            { summaryData.length &&
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>{dollar.format(summaryData.slice(1).reduce((sum, month) => sum + month[1], 0))}</th>
                  <th>{dollar.format(summaryData.slice(1).reduce((sum, month) => sum + month[2], 0))}</th>
                  <th>{dollar.format(summaryData.slice(1).reduce((sum, month) => sum + (month[1] - month[2]), 0))}</th>
                </tr>
              </tfoot>
            }
          </table>
        </div>
      </div>
    </div>
  );
}

export default MonthlySummaryPage;