import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Categories from '../Categories';
import { WithContext as ReactTags } from 'react-tag-input';

function TagModal(props) {
	const [transaction, setTransaction] = useState(null);
	const [categoryName, setCategoryName] = useState("");
	const [tags, setTags] = useState([]);
	const KeyCodes = {
  		comma: 188,
  		enter: 13
	};

	const delimiters = [KeyCodes.comma, KeyCodes.enter];

	useEffect(() => {
    	getTransaction();
  	}, [props.transactionId]);

  	function getTransaction() {
  		props.transactions.forEach((transaction) => {
			if (transaction.id === props.transactionId) {
				const tags = transaction.tags.map((tag) => {
					return {
						id: tag,
						text: tag,
					}
				});
				setTags(tags);
				setTransaction(transaction);
				getCategoryName(transaction);
			}
		});
  	}

  	function formatDate(dateStr) {
  		let date;
  		if (dateStr) {
	    	date = new Date(Date.parse(dateStr));
	    } else {
	    	date = new Date();
	    }
	    return date.toLocaleString('default', {
	      month: 'short',
	      day: 'numeric',
	      year: 'numeric'
	    });
	}

	function getCategoryName(transaction) {
		console.log(transaction);	
		for (let i = 0; i < Categories.length; i++) {
			if (Categories[i].id === transaction.category) {
				setCategoryName(Categories[i].name);
				break;
			}

			for(let x = 0; x < Categories[i].subCategories.length; x++) {
				if (Categories[i].subCategories[x].id === transaction.category) {
					setCategoryName(Categories[i].subCategories[x].name);
					break;
				}
			}
		}
	}

	function handleDelete(i) {
		setTags(tags.filter((tag, index) => index !== i));
	}

	function handleAddition(tag) {
		setTags([...tags, tag]);
	}

	function handleSave() {
		fetch('/api/transactions/' + props.transactionId + '/edit', {
	      	method: 'POST',
	      	headers: {
	        	'Content-Type': 'application/json',
	        	'Authorization': 'Basic ' + Cookies.get('token')
	      	},
	      	body: JSON.stringify({tags: tags.map(tag => tag.text)})
	    })
	      	.then(response => response.json())
	      	.then(data => {
	      		// close modal and refresh transactions
	      		props.getTransactions();
	        	props.close();
	      	})
	      	.catch(err => console.log(err));
	}

	if (!props.open || !props.transactionId) {
		return (
			<div>
			</div>
		);
	}

	return (
		<div>
			<div className="modal modal-open" tabIndex="-1" onClick={props.close}>
				<div className="modal-dialog">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div className="modal-header">
							<div className="modal-title"><h4>{transaction?.name}</h4></div>
							<button type="button" className="btn-close" aria-label="Close" onClick={props.close}></button>
						</div>
						<div className="modal-body">
							<h5>{formatDate(transaction?.date)}</h5>
							<p>{categoryName}</p>
							<div>
								<ReactTags
									tags={tags}
									delimiters={delimiters}
									handleDelete={handleDelete}
									handleAddition={handleAddition}
									inputFieldPosition="bottom"
									classNames={{tagInputField: 'form-control', remove: 'btn btn-sm'}}
									autocomplete
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TagModal;