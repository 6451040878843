import React, { useState } from 'react';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import Cookies from 'js-cookie';

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event) {
    event.preventDefault();

    fetch('/api/users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: email, password: password})
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw new Error(data.error);
        }

        const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
        Cookies.set('token', data.token, { expires: inThirtyMinutes });
        navigate(from, { replace: true });
      })
      .catch(err => {
        console.log(err);
        if (err.message) {
          setError(err.message);
        }
      });
  }

  return (
    <div className="col">
      <div className="row justify-content-center">
        <div className="col-lg-4 vh-100 d-flex align-items-center">
          <div className="w-100 my-4">
            <h2 className="mb-4">Expenses Tracker</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">Email</label>
                <input name="email" id="email" type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="password">Password</label>
                <input name="password" id="password" type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}/>
              </div>
              <div className="d-grid">
                { error && <p className="text-danger">{error}</p> }
                <button type="submit" className="btn btn-primary">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;