import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function AccountsPage() {
  const [accounts, setAccounts] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    getAccounts();
  }, []);

  function getAccounts() {
    fetch('/api/accounts', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setAccounts(data.accounts);
      })
      .catch(err => console.log(err));
  }

  function refreshAccounts() {
    setIsRefreshing(true);
    fetch('/api/accounts/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Cookies.get('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        setAccounts(data.accounts);
        setIsRefreshing(false);
      })
      .catch(err => {
        setIsRefreshing(false);
        console.log(err);
      });
  }

  function formatDateTime(date) {
    const dateObj = new Date(Date.parse(date));
    return dateObj.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
  }

  return (
    <div className="col-lg-10 page-container">
      <h2>Accounts</h2>
      <div className="row">
        <div className="col">
          <p>
            { isRefreshing &&
              <button className="btn btn-primary" disabled="disabled">
                Refreshing&nbsp;
                <div class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Refreshing...</span>
                </div>
              </button>
            }
            { !isRefreshing &&
              <button className="btn btn-primary" onClick={refreshAccounts}>Refresh All</button>
            }
          </p>
          {accounts.map((account) => (
            <div className="card" key={account.id}>
              <div className="card-body">
                <h5 className="card-title">{account.name}</h5>
                <p className="card-text">Last Updated: {formatDateTime(account.updatedAt)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccountsPage;